import {FinanceOneEntry, FinanceTwoEntries,FinanceThreeEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceActivity(){
    const [selectedText, setSelectedText] = useState('AssetTurnover') //default selected value
    return(
        <>


            <Helmet>
                <meta charSet="utf-8" />
                <title>Activity</title>
                <meta name="description" content="Calculate asset turnover, inventory turnover, average collection period, cash conversion cycle, inventory conversion period, receivables turnover, etc." />              
                <meta name="keywords" content="asset turnover,inventory turnover,average collection period,cash conversion cycle,inventory conversion period,receivables turnover"/>
                <meta property="og:title" content="Activity" />        
                <meta property="og:description" content="Calculate asset turnover, inventory turnover, average collection period, cash conversion cycle, inventory conversion period, receivables turnover, etc." />        
                <meta property="og:site_name" content="Activity" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>

      
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Activity</h1>
                    <p>Financial mathematics is all about using mathematical methods to solve problems in finance, particularly involving valuation, risk management, and investment analysis. 
                        In the form below, you can calculate for instance assets turnover, cash conversion cycle, inventories, receivables, etc.
                        </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="AssetTurnover">Asset Turnover </option>
                            <option value="AverageCollection"> Average Collection Period </option>
                            <option value="CashConversionCycle">Cash Conversion Cycle </option>
                            <option value="InventoryConversionPeriod">Inventory Conversion Period </option>
                            <option value="InventoryConversionRatio">Inventory Conversion Ratio </option>
                            <option value="InventoryTurnover">Inventory Turnover </option>
                            <option value="PayablesConversionPeriod">Payables Conversion Period </option>
                            <option value="ReceivablesConversionPeriod">Receivables Conversion Period </option>
                            <option value="ReceivablesTurnoverRatio">Receivables Turnover Ratio </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="AssetTurnover" && <> <FinanceTwoEntries endpoint={"activity/assetturnoverfromnetsalesandtotalassets"} placeHolder1={"Net Sales"}  placeHolder2={"Total Assets"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="AverageCollection" && <> <FinanceTwoEntries endpoint={"activity/averagecollectionperiodfromaccountsreceivableandannualcreditsales"} placeHolder1={"Accounts Receivable"}  placeHolder2={"Annual Credit Sales"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="CashConversionCycle" && <> <FinanceThreeEntries endpoint={"activity/cashconversioncyclefrominventoryconversionperiodreceivablesconversionperiodandpayablesconversionperiod"} placeHolder1={"Inventory Conversion Period"}  placeHolder2={"Receivables Conversion Period"} placeHolder3={"Payables Conversion Period"}></FinanceThreeEntries>   </>}    
                        {selectedText==="InventoryConversionPeriod" && <> <FinanceOneEntry endpoint={"activity/inventoryconversionperiodfrominventoryturnoverratio"} placeHolder1={"Inventory Turnover Ratio"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="InventoryConversionRatio" && <> <FinanceTwoEntries endpoint={"activity/inventoryconversionratiofromsalesandcostofgoodssold"} placeHolder1={"Sales"}  placeHolder2={"Cost Of Goods Sold"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="InventoryTurnover" && <> <FinanceTwoEntries endpoint={"activity/inventoryturnoverfromsalesandaverageinventory"} placeHolder1={"Sales"}  placeHolder2={"Average Inventory"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PayablesConversionPeriod" && <> <FinanceTwoEntries endpoint={"activity/payablesconversionperiodfromaccountspayableandpurchases"} placeHolder1={"Accounts Payable"}  placeHolder2={"Purchases"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReceivablesConversionPeriod" && <> <FinanceTwoEntries endpoint={"activity/receivablesconversionperiodfromreceivablesandnetsales"} placeHolder1={"Receivables"}  placeHolder2={"Sales"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReceivablesTurnoverRatio" && <> <FinanceTwoEntries endpoint={"activity/receivablesturnoverratiofromnetcreditsalesandaveragenetreceivables"} placeHolder1={"Net Credit Sales"}  placeHolder2={"Average Net Receivables"} ></FinanceTwoEntries>   </>}    



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceActivity;