import students from "../assets/images/happy-student.png";
import businessidea from "../assets/images/business-idea.png";
import Footer from "./Footer";
import {Helmet} from "react-helmet";

function Home() {
  return (
<>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Facile Math Home page</title>
        <meta name="description" content="Facile Math covers Algebra, Calculus, Finance, Statistics, Topography and Trigonometry." />              
        <meta name="keywords" content="algebra,calculus,finance,statistics,topography,trigonometry"/>
        <meta property="og:title" content="Facile Math Home page" />        
        <meta property="og:description" content="Facile Math covers Algebra, Calculus, Finance, Statistics, Topography and Trigonometry." />        
        <meta property="og:site_name" content="Facile Math Home page" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>

    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-6 mx-auto">
        <div className="flex flex-wrap -m-12">
          <div className="p-12 md:w-1/2 flex flex-col items-start">
          <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-black-500 dark:text-white leading-normal">Mathematics made easy with <strong>Facile Math</strong>.</h1>
              <p className="text-black-500 dark:text-white mt-4 mb-6">
              Math is a universal language. It's essential for many of your daily tasks. 
              You may use it in an academic context, but you also likely use it daily to perform real-life skills, 
              like grocery shopping, cooking and tracking your finances. 
              Facile math, through the topics listed below, provides <b>quality content to upskill your mathematics knowledge</b>.
              </p>
          </div>
          <div className="p-12 md:w-1/2 flex flex-col items-start">
          <div className="flex w-full">
              <div className="h-full w-full" >
                <img src={students} alt="Math" /> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-wrap -m-5">
      <div className="p-4 lg:w-1/3">
        {/**Calculus */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#318CE7" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="M480-40q-33 0-56.5-23.5T400-120q0-33 23.5-56.5T480-200q33 0 56.5 23.5T560-120q0 33-23.5 56.5T480-40ZM240-760q-33 0-56.5-23.5T160-840q0-33 23.5-56.5T240-920q33 0 56.5 23.5T320-840q0 33-23.5 56.5T240-760Zm0 240q-33 0-56.5-23.5T160-600q0-33 23.5-56.5T240-680q33 0 56.5 23.5T320-600q0 33-23.5 56.5T240-520Zm0 240q-33 0-56.5-23.5T160-360q0-33 23.5-56.5T240-440q33 0 56.5 23.5T320-360q0 33-23.5 56.5T240-280Zm480-480q-33 0-56.5-23.5T640-840q0-33 23.5-56.5T720-920q33 0 56.5 23.5T800-840q0 33-23.5 56.5T720-760ZM480-280q-33 0-56.5-23.5T400-360q0-33 23.5-56.5T480-440q33 0 56.5 23.5T560-360q0 33-23.5 56.5T480-280Zm240 0q-33 0-56.5-23.5T640-360q0-33 23.5-56.5T720-440q33 0 56.5 23.5T800-360q0 33-23.5 56.5T720-280Zm0-240q-33 0-56.5-23.5T640-600q0-33 23.5-56.5T720-680q33 0 56.5 23.5T800-600q0 33-23.5 56.5T720-520Zm-240 0q-33 0-56.5-23.5T400-600q0-33 23.5-56.5T480-680q33 0 56.5 23.5T560-600q0 33-23.5 56.5T480-520Zm0-240q-33 0-56.5-23.5T400-840q0-33 23.5-56.5T480-920q33 0 56.5 23.5T560-840q0 33-23.5 56.5T480-760Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Calculus</h2>
            <p className="text-black-500 dark:text-white">
              permits to solve integrals, derivatives, limits, equations and systems of equations. 
            </p><br />
            <a className="btn bg-blue-400 text-white btn-sm" href="/calculus">Discover</a>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        {/**Algebra */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ED8936" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="M400-240v-80h62l105-120-105-120h-66l-64 344q-8 45-37 70.5T221-120q-45 0-73-24t-28-64q0-32 17-51.5t43-19.5q25 0 42.5 17t17.5 41q0 5-.5 9t-1.5 9q5-1 8.5-5.5T252-221l62-339H200v-80h129l21-114q7-38 37.5-62t72.5-24q44 0 72 26t28 65q0 30-17 49.5T500-680q-25 0-42.5-17T440-739q0-5 .5-9t1.5-9q-6 2-9 6t-5 12l-17 99h189v80h-32l52 59 52-59h-32v-80h200v80h-62L673-440l105 120h62v80H640v-80h32l-52-60-52 60h32v80H400Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Algebra</h2>
            <p className="text-black-500 dark:text-white">
            deals with polynomials, complex numbers, inequalities, Fibonacci numbers, prime numbers, slope, intercept and equation of lines.
            </p>
            <a className="btn bg-orange-500 text-white btn-sm" href="/algebra">Discover</a>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        {/**Trigonometry */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#318CE7" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="m80-160 400-640 400 640H80Zm144-80h512L480-650 224-240Zm256-205Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Trigonometry</h2>
            <p className="text-black-500 dark:text-white">
            covers angle conversion, trigonometric functions, trigonometric equations and the equation of a circle.
            </p>
            <a className="btn bg-blue-400 text-white btn-sm" href="/trigonometry">Discover</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="text-gray-600 body-font">
  <div className="container px-5 py-6 mx-auto">
    <div className="flex flex-wrap -m-5">
      <div className="p-4 lg:w-1/3">
      {/**Statistics */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ED8936" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="M520-520h278q-15-110-91.5-186.5T520-798v278Zm-80 358v-636q-121 15-200.5 105.5T160-480q0 122 79.5 212.5T440-162Zm80 0q110-14 187-91t91-187H520v278Zm-40-318Zm0 400q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Statistics</h2>
            <p className="text-black-500 dark:text-white">
            explores a dataset's core statistics concepts such as the mean, mode, median, variance, standard deviation, nth percentile, and Z score.
            </p>
            <a className="btn bg-orange-500 text-white btn-sm" href="/statistics">Discover</a>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        {/**Areas and volumes */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#318CE7" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-84 31-156.5T196-764l284 284v-400q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Areas and volumes</h2>
            <p className="text-black-500 dark:text-white">
            permit calculating the areas and volumes of geometric figures such as pyramids, pentagons, icosahedrons, cones, cylinders, boxes, and ellipsoids.
            </p>
            <a className="btn bg-blue-400 text-white btn-sm" href="/surface">Discover</a>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        {/**Topography */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ED8936" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="m260-260 300-140 140-300-300 140-140 300Zm220-180q-17 0-28.5-11.5T440-480q0-17 11.5-28.5T480-520q17 0 28.5 11.5T520-480q0 17-11.5 28.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Topography</h2>
            <p className="text-black-500 dark:text-white">
            covers bearings, distance, areas and volumes, changes in coordinate systems, triangle resolutions and intersections.
            </p><br />
            <a className="btn bg-orange-500 text-white btn-sm" href="/topography">Discover</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="text-gray-600 body-font">
  <div className="container px-5 py-6 mx-auto">
    <div className="flex flex-wrap -m-5">
      <div className="p-4 lg:w-1/3">
        {/**Financial */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#318CE7" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="M240-120v-80l16.5-10q16.5-10 36-29.5t35.5-50q16-30.5 16-70.5 0-11-1.5-21t-3.5-19h-99v-80h60q-21-33-40.5-69.5T240-640q0-92 64-156t156-64q71 0 126 39t79 101l-74 31q-15-40-50.5-65.5T460-780q-58 0-99 41t-41 99q0 48 24 80t49 80h167v80H421q2 9 2.5 19t.5 21q0 50-17.5 90T364-200h196q40 0 61-21t29-54l70 35q-11 55-56.5 87.5T560-120H240Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Financial math</h2>
            <p className="text-black-500 dark:text-white">
            covers calculations related to banking, corporate finance, financial markets, stocks and bonds, debt, activity, profitability, depreciation, liquidity, Market and much more.
            </p>
            <a className="btn bg-blue-400 text-white btn-sm" href="/finance">Discover</a>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        {/**Constants */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ED8936" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="M200-120q-51 0-72.5-45.5T138-250l222-270v-240h-40q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760h-40v240l222 270q32 39 10.5 84.5T760-120H200Zm0-80h560L520-492v-268h-80v268L200-200Zm280-280Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Constants</h2>
            <p className="text-black-500 dark:text-white">
            is a massive collection of mathematical and physical constants as well as constants related to planets.
            </p><br />
            <a className="btn bg-orange-500 text-white btn-sm" href="/science">Discover</a>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        {/**Math functions */}
        <div className="card shadow w-80 m-4 dark:bg-slate-800">
          <div className="card-body flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#318CE7" height="24" viewBox="0 -960 960 960" width="24" stroke="currentColor"><path d="M240-160v-80l260-240-260-240v-80h480v120H431l215 200-215 200h289v120H240Z"/></svg>
            <h2 className="text-black-500 dark:text-white card-title"> Math functions</h2>
            <p className="text-black-500 dark:text-white">
            help calculate factorials, double factorials, Gamma function, Beta function, Stirling function, Error functions, GCD and LCM.
            </p><br />
            <a className="btn bg-blue-400 text-white btn-sm" href="/functions">Discover</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-12">
          <div className="p-12 md:w-1/2 flex flex-col items-start">
            <div className="flex w-full">
              <div className="h-full w-full" >
                <img src={businessidea} alt="Math" /> 
              </div>
            </div>
          </div>
          <div className="p-12 md:w-1/2 flex flex-col items-start">
            <h1 className="text-2xl lg:text-3xl xl:text-4xl font-medium text-black-500 dark:text-white leading-normal">
              Do you need help in <strong>Math</strong> ?
              </h1>
              <p className="text-black-500 dark:text-white mt-4 mb-6">All math you'll ever need is gathered here in one place!

              </p>
              <a className="btn btn-info btn-wide" href="/about">Learn more about us</a>
          </div>
        </div>
      </div>
    </section>

    
<Footer></Footer>









</>


    
  );
}
export default Home;