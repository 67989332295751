import {FinanceTwoEntries,FinanceThreeEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceLiquidity(){
    const [selectedText, setSelectedText] = useState('CashRation') //default selected value
    return(
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Liquidity</title>
                <link rel="canonical" href="https://facilemath.com/finance-liquidity"/>
                <meta name="description"
                      content="Calculate cash ratio, current ratio, operating cash flow ratio, quick ratio, etc."/>
                <meta name="keywords" content="cash ratio,current ratio,operating cash flow ratio,quick ratio"/>
                <meta property="og:title" content="Liquidity"/>
                <meta property="og:description"
                      content="Calculate cash ratio, current ratio, operating cash flow ratio, quick ratio, etc."/>
                <meta property="og:site_name" content="Liquidity"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>

            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Liquidity</h1>
                    <p>Liquidity in finance refers to how easily an asset can be converted into cash without affecting its price. 
                        Cash is the most liquid asset, while things like real estate or collectibles are less liquid because they take time to sell and might not sell at their expected value. 
                        A company with good liquidity can meet its short-term obligations easily. 
                        It's like having ready cash in your pocket versus having wealth tied up in assets that take time to cash out.
                    In the following form, you can calculate for example the cash ratio, current ratio, operating cash ratio and quick ratio.
                    </p><br /> <hr /> <br />
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="CashRation">Cash Ratio </option>
                            <option value="CurrentRatio">Current Ratio </option>
                            <option value="OperatingCashFlow">Operating Cash Flow Ratio </option>
                            <option value="QuickRatio">Quick Ratio </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="CashRation" && <> <FinanceThreeEntries endpoint={"liquidity/cashrationfromcashmarketablesecuritiesandcurrentliabilities"} placeHolder1={"Cash"}  placeHolder2={"Marketable Securities"} placeHolder3={"Current Liabilities"}></FinanceThreeEntries>   </>}    
                        {selectedText==="CurrentRatio" && <> <FinanceTwoEntries endpoint={"liquidity/currentratiofromcurrentassetsandcurrentliabilties"} placeHolder1={"Current Assets"}  placeHolder2={"Current Liabilities"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="OperatingCashFlow" && <> <FinanceTwoEntries endpoint={"liquidity/operatingcashflowratiofromoperatingcashflowandtotaldebts"} placeHolder1={"Operating Cash Flow"}  placeHolder2={"Total Debts"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="QuickRatio" && <> <FinanceThreeEntries endpoint={"liquidity/quickratiofromcurrentassetsinventoriesandcurrentliabilities"} placeHolder1={"Current Assets"}  placeHolder2={"Inventories"} placeHolder3={"Current Liabilities"}></FinanceThreeEntries>   </>}    



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceLiquidity;