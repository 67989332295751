import {React, useState} from "react";
import {Helmet} from "react-helmet";
import {DistributionTwoEntries, DistributionThreeEntries, DistributionFourEntries} from "../statistics/StatisticsTemplates";
import StatisticsAffiliate from "./StatisticsAffiliate";

function StatisticsContinuous(){
    const [selectedText, setSelectedText] = useState('cont') //default selected value
    return(
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Learn the core concepts of continuous distributions.</title>
                <meta name="description" content="It covers continuous uniform, normal, log normal, beta, cauchy, chi, chi squared, erlang, exponential, fisher-snedecor, gamma, inverse gamma, laplace, pareto, Rayleigh, stable, student-T, triangle, and weibull distributions." />              
                <meta name="keywords" content="continuous uniform,normal,log normal,beta,cauchy,chi,chi squared,erlang,exponential,fisher-snedecor,gamma,inverse gamma,laplace,pareto,Rayleigh,stable,student-T,triangle,weibull distributions"/>
                <meta property="og:title" content="Learn the core concepts of continuous distributions." />        
                <meta property="og:description" content="It covers continuous uniform, normal, log normal, beta, cauchy, chi, chi squared, erlang, exponential, fisher-snedecor, gamma, inverse gamma, laplace, pareto, Rayleigh, stable, student-T, triangle, and weibull distributions." />        
                <meta property="og:site_name" content="Learn the core concepts of continuous distributions." />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>

           

            <section className="text-base-content body-font relative dark:text-white">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col w-full mb-12">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400  flex justify-center items-center py-2">Continuous Distributions</h1>
                    <p>Continuous probability distributions are used to model scenarios where the data can take any value within a certain range. 
                        Here are a few of the major ones: <br />
                        &#9724; Normal Distribution <br />
                        &#9724; Uniform Distribution <br />
                        &#9724; Exponential Distribution <br />
                        &#9724; Beta Distribution <br />
                        &#9724; Gamma Distribution
                    </p><br /><hr /><br />
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="cont">Continuous Uniform</option>
                            <option value="norm">Normal</option>
                            <option value="log">Log Normal</option>
                            <option value="beta">Beta</option>
                            <option value="cauchy">Cauchy (Cauchy-Lorentz)</option>
                            <option value="chi">Chi</option>
                            <option value="chis">Chi Squared</option>
                            <option value="erl">Erlang</option>
                            <option value="exp">Exponential</option>
                            <option value="fish">Fisher-Snedecor (F-Distribution)</option>
                            <option value="gamma">Gamma</option>
                            <option value="igamma">Inverse Gamma</option>
                            <option value="lap">Laplace</option>
                            <option value="par">Pareto</option>
                            <option value="ray">Rayleigh</option>
                            <option value="sta">Stable</option>
                            <option value="stu">Student-T</option>
                            <option value="wei">Weibull</option>
                            <option value="tri">Triangular</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="cont" && <DistributionThreeEntries endpoint={"continuous-uniform"} placeHolder1={"Lower bound"} placeHolder2={"Upper bound"} placeHolder3={"x"}></DistributionThreeEntries>}
                            {selectedText==="norm" && <DistributionThreeEntries endpoint={"normal"} placeHolder1={"Mean"} placeHolder2={"Standard deviation"} placeHolder3={"x (default 0.0)"}></DistributionThreeEntries>}
                            {selectedText==="log" && <DistributionThreeEntries endpoint={"log-normal"} placeHolder1={"Mean"} placeHolder2={"Standard deviation"} placeHolder3={"x (default 1.0)"}></DistributionThreeEntries>}
                            {selectedText==="beta" && <DistributionThreeEntries endpoint={"beta"} placeHolder1={"Shape parameter α"} placeHolder2={"Shape parameter β"} placeHolder3={"x (default 0.5)"}></DistributionThreeEntries>}
                            {selectedText==="cauchy" && <DistributionThreeEntries endpoint={"cauchy"} placeHolder1={"Location"} placeHolder2={"Scale"} placeHolder3={"x (default 0.0)"}></DistributionThreeEntries>}
                            {selectedText==="chi" && <DistributionTwoEntries endpoint={"chi"} placeHolder1={"Degree of freedom"} placeHolder2={"x (default 1.0)"}></DistributionTwoEntries>}
                            {selectedText==="chis" && <DistributionTwoEntries endpoint={"chi-squared"} placeHolder1={"Degree of freedom"} placeHolder2={"x (default 1.0)"}></DistributionTwoEntries>}
                            {selectedText==="erl" && <DistributionThreeEntries endpoint={"erlang"} placeHolder1={"Shape (integer)"} placeHolder2={"Rate"} placeHolder3={"x (default 2.0)"}></DistributionThreeEntries>}
                            {selectedText==="exp" && <DistributionTwoEntries endpoint={"exponential"} placeHolder1={"Rate"} placeHolder2={"x (default 1.0)"}></DistributionTwoEntries>}
                            {selectedText==="fish" && <DistributionThreeEntries endpoint={"fisher-snedecor"} placeHolder1={"Degree of freedom d1"} placeHolder2={"Degree of freedom d2"} placeHolder3={"x (default 1.0)"}></DistributionThreeEntries>}
                            {selectedText==="gamma" && <DistributionThreeEntries endpoint={"gamma"} placeHolder1={"Shape"} placeHolder2={"Scale"} placeHolder3={"x (default 1.0)"}></DistributionThreeEntries>}
                            {selectedText==="igamma" && <DistributionThreeEntries endpoint={"inverse-gamma"} placeHolder1={"Shape"} placeHolder2={"Scale"} placeHolder3={"x (default 1.0)"}></DistributionThreeEntries>}
                            {selectedText==="lap" && <DistributionThreeEntries endpoint={"laplace"} placeHolder1={"Location"} placeHolder2={"Scale"} placeHolder3={"x (default 1.0)"}></DistributionThreeEntries>}
                            {selectedText==="par" && <DistributionThreeEntries endpoint={"pareto"} placeHolder1={"Scale xm"} placeHolder2={"Shape α"} placeHolder3={"x"}></DistributionThreeEntries>}
                            {selectedText==="ray" && <DistributionTwoEntries endpoint={"rayleigh"} placeHolder1={"Scale"} placeHolder2={"x (default 1.0)"}></DistributionTwoEntries>}
                            {selectedText==="sta" && <DistributionFourEntries endpoint={"stable"} placeHolder1={"Stability parameter α"} placeHolder2={"Skewness parameter β"} placeHolder3={"Scale parameter c"}  placeHolder4={"Location parameter u"}></DistributionFourEntries>}
                            {selectedText==="stu" && <DistributionTwoEntries endpoint={"student-t"} placeHolder1={"Degree of freedom"} placeHolder2={"x (default 1.0)"}></DistributionTwoEntries>}
                            {selectedText==="wei" && <DistributionThreeEntries endpoint={"weibull"} placeHolder1={"Shape"} placeHolder2={"Scale"} placeHolder3={"x (default 1.0)"}></DistributionThreeEntries>}
                            {selectedText==="tri" && <DistributionFourEntries endpoint={"triangular"} placeHolder1={"Lower bound"} placeHolder2={"Upper bound"} placeHolder3={"Mode"}  placeHolder4={"x"}></DistributionFourEntries>}





                        </div>
                        <div className="py-12"> 
                        {/*  <StatisticsAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>


        </>
    )
}
export default StatisticsContinuous;
