import {FinanceTwoEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceDebt(){
    const [selectedText, setSelectedText] = useState('DebttoEquity') //default selected value
    return(
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Debt</title>
                <meta name="description" content="Calculate debt to equity ratio, debt ratio, debt-service coverage ratio, etc." />              
                <meta name="keywords" content="debt to equity ratio,debt ratio,debt-service coverage ratio"/>
                <meta property="og:title" content="Debt" />        
                <meta property="og:description" content="Calculate debt to equity ratio, debt ratio, debt-service coverage ratio, etc." />        
                <meta property="og:site_name" content="Debt" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>


       
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Debt</h1>
                    <p>
                    Debt is the borrowed money that you need to pay back. In the following form, you can calculate for instance 
                    debt to equity ratio, debt ratio, debt-service coverage ratio, and long-term debt to equity ratio.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="DebttoEquity">Debt to Equity Ratio </option>
                            <option value="DebtRatio">Debt Ratio </option>
                            <option value="Debt-ServiceCoverageRatio">Debt-Service Coverage Ratio </option>
                            <option value="Long-TermDebtto">Long-Term Debt to Equity Ratio </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="DebttoEquity" && <> <FinanceTwoEntries endpoint={"debt/debttoequityratiofromtotalliabilitiesandshareholderequity"} placeHolder1={"Total Liabilities"}  placeHolder2={"Shareholder Equity"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DebtRatio" && <> <FinanceTwoEntries endpoint={"debt/debtratiofromtotalliabilitiesandtotalassets"} placeHolder1={"Total Liabilitie"}  placeHolder2={"Total Assets"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="Debt-ServiceCoverageRatio" && <> <FinanceTwoEntries endpoint={"debt/debt-servicecoverageratiofromnetoperatingincomeandtotaldebtservice"} placeHolder1={"Net Operating Income"}  placeHolder2={"Total Debt Service"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="Long-TermDebtto" && <> <FinanceTwoEntries endpoint={"debt/long-termdebttoequityratiofromlongtermliabilitiesandequity"} placeHolder1={"Long-Term Liabilities"}  placeHolder2={"Equity"} ></FinanceTwoEntries>   </>}   



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceDebt;