import {React} from "react";
import {Helmet} from "react-helmet";
import ScienceTemplate from "./ScienceTemplates";



function ScienceSolar(){
    return(
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Solar system constants</title>
                <meta name="description" content="Learn constants related to the solar system and its planets" />              
                <meta name="keywords" content="constants,solar system constants"/>
                <meta property="og:title" content="Solar system constants" />        
                <meta property="og:description" content="Learn constants related to the solar system and its planets" />        
                <meta property="og:site_name" content="Solar system constants" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>
        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400  flex justify-center items-center py-2">Solar system</h1>
                    <p>Solar System constants permit a better understanding of the solar system and its planets.</p><br />
                        <div>
                            <> <ScienceTemplate endpoint={"solar"} ></ScienceTemplate>   </>     
                        </div>
                        <div className="card shadow w-full">
                             
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default ScienceSolar;