import {React, useState} from "react";
import {Helmet} from "react-helmet";
import { TrigonometryOneEntry, TrigonometryTwoEntries, TrigonometryThreeEntries } from "./TrigonometryEquationsTemplates";
import TrigonometryAffiliate from "./TrigonometryAffiliate";

function TrigonometryEquation(){
    const [selectedText1, setSelectedText1] = useState('cosxa') //default selected value
    return(
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Trigonometric equations</title>
                <meta name="description" content="Solve trigonometric equations in the form cos(x) = a, a tan²(x) = b tan(x), etc." />              
                <meta name="keywords" content="trigonometry,trigonometric equations"/>
                <meta property="og:title" content="Trigonometric equations" />        
                <meta property="og:description" content="Solve trigonometric equations in the form cos(x) = a, a tan²(x) = b tan(x), etc." />        
                <meta property="og:site_name" content="Trigonometric equations" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>




            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col text-center w-full mb-12">
                    <h1 className="card-title text-xl text-sky-400  flex justify-center items-center py-2 mb-4">Trigonometric Equations</h1>
                    <p>Solving trigonometric equations involves finding the angles that satisfy a given equation.
                        That usually involves the use of trigonometric identities and a combination of trigonometric functions.
                    </p><br /><hr />
                            <div className="mb-2">
                                <select className="select select-bordered w-full" value={selectedText1} onChange={event => setSelectedText1(event.target.value)}>
                                    <option value="cosxa">Solve cos(x) = a</option>
                                    <option value="sinxa">Solve sin(x) = a</option>
                                    <option value="tanxa">Solve tan(x) = a</option>
                                    <option value="cosxab">Solve cos( a x) = b</option>
                                    <option value="sinxab">Solve sin( a x) = b</option>
                                    <option value="tanxab">Solve tan( a x) = b</option>
                                    <option value="tan2xab">Solve a tan²(x) = b tan(x)</option>
                                    <option value="cos2xab">Solve a cos²(x) + b sin(x) = c</option>
                                    <option value="sin2xab">Solve a sin²(x) + b cos(x) = c</option>
                                </select>
                            </div>
                        {selectedText1 === "cosxa" && <TrigonometryOneEntry calculus={"cosxa"} ></TrigonometryOneEntry>}
                        {selectedText1 === "sinxa" && <TrigonometryOneEntry calculus={"sinxa"} ></TrigonometryOneEntry>}
                        {selectedText1 === "tanxa" && <TrigonometryOneEntry calculus={"tanxa"} ></TrigonometryOneEntry>}
                        {selectedText1 === "cosxab" && <TrigonometryTwoEntries calculus={"cosxab"} ></TrigonometryTwoEntries>}
                        {selectedText1 === "sinxab" && <TrigonometryTwoEntries calculus={"sinxab"}></TrigonometryTwoEntries>}
                        {selectedText1 === "tanxab" && <TrigonometryTwoEntries calculus={"tanxab"}></TrigonometryTwoEntries>}

                        {selectedText1 === "tan2xab" && <TrigonometryTwoEntries calculus={"quadratic/tan"} ></TrigonometryTwoEntries>}
                        {selectedText1 === "cos2xab" && <TrigonometryThreeEntries calculus={"quadratic/cos"}></TrigonometryThreeEntries>}
                        {selectedText1 === "sin2xab" && <TrigonometryThreeEntries calculus={"quadratic/sin"}></TrigonometryThreeEntries>}
                        <div className="py-12"> 
                        {/*  <TrigonometryAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default TrigonometryEquation;