import {TopographyThreeEntries} from "./TopographyTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import angle_image from "../../assets/images/triangle1.png";
    import three_image from "../../assets/images/triangle2.png";
    import two_image from "../../assets/images/triangle3.png";
    import TopographyAffiliate from "./TopographyAffiliate";

function TopographyTriangle(){
    const [selectedText, setSelectedText] = useState('angle') //default selected value
    return(
        <>


            <Helmet>
                <meta charSet="utf-8"/>
                <title>Triangle resolutions</title>
                <link rel="canonical" href="https://facilemath.com/topography-triangle"/>
                <meta name="description" content="Calculate angles and distances using Triangle resolutions"/>
                <meta name="keywords" content="Triangle resolutions"/>
                <meta property="og:title" content="Triangle resolutions"/>
                <meta property="og:description" content="Calculate angles and distances using Triangle resolutions"/>
                <meta property="og:site_name" content="Triangle resolutions"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>


            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400 flex justify-center items-center py-2">Triangle resolutions</h1>
                    <p>To solve a triangle, you typically need to find the unknown sides and angles.
                        In the form below, you can solve a triangle based on the sides and angles your know.
                    </p><br /><hr /><br />
                     
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="angle">Angle and two known adjacent sides</option>
                                <option value="three">Three known sides</option>
                                <option value="two">Two angles and one side</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="angle" && <TopographyThreeEntries endpoint={"resotriangle/anglecotes"} image={angle_image} placeHolder1={"Angle C in gradians"} placeHolder2="Distance a" placeHolder3={"Distance b"} ></TopographyThreeEntries>}
                            {selectedText==="three" && <TopographyThreeEntries endpoint={"resotriangle/troiscotes"} image={three_image} placeHolder1={"Distance a"} placeHolder2="Distance b"  placeHolder3={"Distance c"}></TopographyThreeEntries>}
                            {selectedText==="two" && <TopographyThreeEntries endpoint={"resotriangle/deuxanglescote"} image={two_image} placeHolder1={"Angle A in gradians"} placeHolder2="Distance b"  placeHolder3={"Angle C in gradians"}></TopographyThreeEntries>}
                            
                           
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyTriangle;