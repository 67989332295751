import { CalculusOneEntry,
    AngouriPolynomialGuide, DerivativeExample}
 from "./CalculusTemplates";
    import {React} from "react";
    import {Helmet} from "react-helmet";
    import CalculusAffiliate from "./CalculusAffiliate";



function CalculusDerivative(){
    return(
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Derivatives</title>
                <link rel="canonical" href="https://facilemath.com/calculus-derivative"/>
                <meta name="description"
                      content="Find the derivatives of polynomials, trigonometric and exponential functions."/>
                <meta name="keywords" content="derivative,derivative of a function"/>
                <meta property="og:title" content="Derivatives"/>
                <meta property="og:description"
                      content="Find the derivatives of polynomials, trigonometric and exponential functions."/>
                <meta property="og:site_name" content="Derivatives"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>


            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Differentiation</h1>
                    <p>A derivative in mathematics represents the rate of change of a function with respect to a variable. 
                        It's a fundamental concept in calculus. Essentially, it tells you how a function's output changes as its input changes.
                        Derivatives are crucial for understanding and modeling change and are used in a wide array of fields, from physics to economics.
                        In the form below, enter a function or polynomial in order to compute its derivative.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                    </div>
                        <div>
                          <> <CalculusOneEntry endpoint={"differentiation"}  placeHolder1={"Enter a function "} ></CalculusOneEntry> <DerivativeExample></DerivativeExample>  </>    
                            
                        </div>
                        <div className="card shadow w-full">
                             <AngouriPolynomialGuide ></AngouriPolynomialGuide> 
                        </div>
                        <div className="py-12"> 
                        {/*  <CalculusAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default CalculusDerivative;