import calculusrmbg from "../assets/images/calculusrmbg.png";


export default function Footer() {
    return (
        <>
        <footer className="footer bg-base-200 text-base-content p-10 text-gray-600 dark:text-gray-100">
  <aside>
    <a href="/"><img src={calculusrmbg} alt="logo" height={40} width={40} /></a>
    <p className="text-xl text-sky-400">
      <b>Facilemath.com</b> 
      <br />
      <span className="text-xs text-gray-600 dark:text-gray-100">All math topics and beyond</span>
      
    </p>
  </aside>
  <nav>
    <h6 className="footer-title">Resources</h6>
    <a href="https://alleramusic.com" target="_blank" className="link link-hover">All Era Music</a>
    <a href="https://libpeople.com" target="_blank" className="link link-hover">Library of the People</a>
    <a href="https://dreamsgist.com" target="_blank"  className="link link-hover">Dreams Gist</a>
  </nav>
  <nav>
    <h6 className="footer-title">Company</h6>
    <a href="/about" className="link link-hover">About us</a>
    <a href="/contact"  className="link link-hover">Contact</a>
  </nav>
  <nav>
    <h6 className="footer-title ">Legal</h6>
    <a href="/terms"  className="link link-hover">Terms of use</a>
    <a href="/policy"  className="link link-hover">Privacy policy</a>
    <a href="/cookies"  className="link link-hover">Cookies policy</a>
  </nav>
</footer>
        </>
    )
}