import { TopographyFiveEntries,TopographySixEntries} from "./TopographyTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import lines_image from "../../assets/images/interdroites.png";
    import circles_image from "../../assets/images/intercercles.png";
    import linescircle_image from "../../assets/images/droitecercle.png";
    import TopographyAffiliate from "./TopographyAffiliate";

function TopographyIntersection(){
    const [selectedText, setSelectedText] = useState('lines') //default selected value
    return(
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Intersections</title>
                <link rel="canonical" href="https://facilemath.com/topography-intersection"/>
                <meta name="description"
                      content="Calculate the coordinates of the intersection point of two lines, two circles, and lines tangent to a circle"/>
                <meta name="keywords"
                      content="intersections,intersection of two lines,intersection of lines and circle"/>
                <meta property="og:title" content="Intersections"/>
                <meta property="og:description"
                      content="Calculate the coordinates of the intersection point of two lines, two circles, and lines tangent to a circle"/>
                <meta property="og:site_name" content="Intersections"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>

            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400  flex justify-center items-center py-2">Intersections</h1>
                    <p>
                    Finding the intersection of a geometric figure and a line involves solving their equations simultaneously. 
                    In the form below, you can determine the intersection point of two circles, two lines as well as the intersection point of a circle and a line.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="lines">Intersection of two lines</option>
                                <option value="circles">Intersection of two circles</option>
                                <option value="linescircle">Lines tangent to a circle</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="lines" && 
                            <TopographySixEntries endpoint={"intersection/lines"} image={lines_image} 
                            placeHolder1={"Angle GA in gradians"} placeHolder2="Angle GB in gradians" placeHolder3={"XA"} placeHolder4="YA"  placeHolder5={"XB"} placeHolder6="YB">
                                </TopographySixEntries>}
                                {selectedText==="circles" && 
                            <TopographySixEntries endpoint={"intersection/circles"} image={circles_image} 
                            placeHolder1={"R1"} placeHolder2="R2" placeHolder3={"XO1"} placeHolder4="YO1"  placeHolder5={"XO2"} placeHolder6="YO2">
                                </TopographySixEntries>}
                                {selectedText==="linescircle" && <TopographyFiveEntries endpoint={"intersection/linecircle"} image={linescircle_image} 
                            placeHolder1={"R"} placeHolder2="XA" placeHolder3={"YA"} placeHolder4="XO"  placeHolder5={"YO"} >
                                </TopographyFiveEntries>}
                            
                           
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyIntersection;