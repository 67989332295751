import {FinanceOneEntry, FinanceTwoEntries,FinanceThreeEntries,FinanceFourEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceGeneral(){
    const [selectedText, setSelectedText] = useState('FutureValueof1') //default selected value
    return(
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>General finance</title>
                <meta name="description" content="Calculate future value of annuity, present value annuity payment, average collection period, doubling time, rule of 72, etc." />              
                <meta name="keywords" content="future value of annuity,present value annuity payment,average collection period,doubling time,rule of 72"/>
                <meta property="og:title" content="General finance" />        
                <meta property="og:description" content="Calculate future value of annuity, present value annuity payment, average collection period, doubling time, rule of 72, etc." />        
                <meta property="og:site_name" content="General finance" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>


        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">General Finance</h1>
                    <p> General finance is the broad field that deals with managing money, including personal finance, corporate finance, and public finance.
                    In the form below, you can calculate present and future values, average collection period and, growing annuity.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full text-xs" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="FutureValueof1">Future Value of Annuity </option>
                            <option value="FutureValueof2">Fut. Value of Annuity with Continuous Compounding </option>
                            <option value="NumberofPeriods1">Number of Periods for Future Value of Annuity </option>
                            <option value="PresentValueAnnuity1">Present Value Annuity Payment </option>
                            <option value="FutureValueAnnuity">Future Value Annuity Payment </option>
                            <option value="NumberofPeriods2">Num. of Periods for Present Value of Annuity </option>
                            <option value="PresentValueof1">Present Value of Annuity </option>
                            <option value="AverageCollectionPeriod">Average Collection Period </option>
                            <option value="PresentValueAnnuity2">Present Value Annuity Factor </option>
                            <option value="PresentValueof2">Present Value of Annuity Due </option>
                            <option value="FutureValueof3">Future Value of Annuity Due </option>
                            <option value="AnnuityDuePayment1">Annuity Due Payment using Present Value </option>
                            <option value="AnnuityDuePayment2">Annuity Due Payment using Future Value </option>
                            <option value="DoublingTime">Doubling Time </option>
                            <option value="DoublingTimewith">Doubling Time with Continuous Compounding </option>
                            <option value="DoublingTimeFor">Doubling Time For Simple Interest</option>
                            <option value="FutureValue">Future Value </option>
                            <option value="FutureValuewith">Future Value with Continuous Compounding </option>
                            <option value="FutureValueFactor">Future Value Factor </option>
                            <option value="FutureValueof4">Future Value of Growing Annuity </option>
                            <option value="GrowingAnnuityPayment1">Growing Annuity Payment From Present </option>
                            <option value="GrowingAnnuityPayment2">Growing Annuity Payment From Future </option>
                            <option value="PresentValueof3">Present Value of Growing Annuity </option>
                            <option value="PresentValueof4">Present Value of Growing Perpetuity </option>
                            <option value="NumberofPeriods3">Num. of Periods for Present Value to reach Fut. Value </option>
                            <option value="PresentValueof5">Present Value of Perpetuity </option>
                            <option value="PresentValue">Present Value </option>
                            <option value="PresentValuewith">Present Value with Continuous Compounding </option>
                            <option value="PresentValueFactor">Present Value Factor </option>
                            <option value="Ruleof72">Rule of 72 </option>
                            <option value="RateRequiredto">Rate Required to Double </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="FutureValueof1" && <> <FinanceThreeEntries endpoint={"general/futurevalueofannuityfromperiodicpaymentrateperperiodandnumberofperiods"} placeHolder1={"Periodic Payment"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="FutureValueof2" && <> <FinanceThreeEntries endpoint={"general/futurevalueofannuitywithcontinuouscompoundingfromcashflowrateandtime"} placeHolder1={"Cash Flow"}  placeHolder2={"Rate"} placeHolder3={"Time"}></FinanceThreeEntries>   </>}    
                        {selectedText==="NumberofPeriods1" && <> <FinanceThreeEntries endpoint={"general/numberofperiodsforfuturevalueofannuityfromfuturevalueofannuityrateandpayment"} placeHolder1={"Future Value of Annuity"}  placeHolder2={"Rate"} placeHolder3={"Payment"}></FinanceThreeEntries>   </>}    
                        {selectedText==="PresentValueAnnuity1" && <> <FinanceThreeEntries endpoint={"general/presentvalueannuitypaymentfrompresentvaluerateperperiodandnumberofperiods"} placeHolder1={"Present Value"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="FutureValueAnnuity" && <> <FinanceThreeEntries endpoint={"general/futurevalueannuitypaymentfromfuturevaluerateperperiodandnumberofperiods"} placeHolder1={"Future Value"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="NumberofPeriods2" && <> <FinanceThreeEntries endpoint={"general/numberofperiodsforpresentvalueofannuityfrompresentvalueofannuityrateandpayment"} placeHolder1={"Present Value of Annuity"}  placeHolder2={"Rate"} placeHolder3={"Payment"}></FinanceThreeEntries>   </>}    
                        {selectedText==="PresentValueof1" && <> <FinanceThreeEntries endpoint={"general/presentvalueofannuityfromperiodicpaymentrateperperiodandnumberofperiods"} placeHolder1={"Present Value of Annuity"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="AverageCollectionPeriod" && <> <FinanceOneEntry endpoint={"general/averagecollectionperiodfromreceivablesturnover"} placeHolder1={"Receivables Turnover"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="PresentValueAnnuity2" && <> <FinanceTwoEntries endpoint={"general/presentvalueannuityfactorfromrateperperiodandnumberofperiods"} placeHolder1={"Rate per Period"}  placeHolder2={"Number of Periods"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PresentValueof2" && <> <FinanceThreeEntries endpoint={"general/presentvalueofannuityduefromperiodicpaymentrateperperiodandnumberofperiods"} placeHolder1={"Periodic Payment"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="FutureValueof3" && <> <FinanceThreeEntries endpoint={"general/futurevalueofannuityduefromperiodicpaymentrateperperiodandnumberofperiods"} placeHolder1={"Periodic Payment"}  placeHolder2={"Rate per Period"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="AnnuityDuePayment1" && <> <FinanceThreeEntries endpoint={"general/annuityduepaymentusingpresentvaluefrompresentvaluerateperperiodandnumberofperiods"} placeHolder1={"Present Value"}  placeHolder2={"Rate per Period"} placeHolder3={"number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="AnnuityDuePayment2" && <> <FinanceThreeEntries endpoint={"general/annuityduepaymentusingfuturevaluefromfuturevaluerateperperiodandnumberofperiods"} placeHolder1={"Future Value"}  placeHolder2={"Rate per Period"} placeHolder3={"number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="DoublingTime" && <> <FinanceOneEntry endpoint={"general/doublingtimefromrateofreturn"} placeHolder1={"Rate of Return"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="DoublingTimewith" && <> <FinanceOneEntry endpoint={"general/doublingtimewithcontinuouscompoundingfromrate"} placeHolder1={"Rate"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="DoublingTimeFor" && <> <FinanceOneEntry endpoint={"general/doublingtimeforsimpleinterest"} placeHolder1={"Rate"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="FutureValue" && <> <FinanceThreeEntries endpoint={"general/futurevaluefrominitialcashflowrateofreturnandnumberofperiods"} placeHolder1={"Initial Cash Flow"}  placeHolder2={"Rate of Return"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="FutureValuewith" && <> <FinanceThreeEntries endpoint={"general/futurevaluewithcontinuouscompoundingfrompresentvaluerateandtime"} placeHolder1={"Present Value"}  placeHolder2={"Rate"} placeHolder3={"Time"}></FinanceThreeEntries>   </>}    
                        {selectedText==="FutureValueFactor" && <> <FinanceTwoEntries endpoint={"general/futurevaluefactorfromrateperperiodandnumberofperiods"} placeHolder1={"Rate per Period"}  placeHolder2={"Number of Periods"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="FutureValueof4" && <> <FinanceFourEntries endpoint={"general/futurevalueofgrowingannuityfromfirstpaymentrateperperiodgrowthrateandnumberofperiods"} placeHolder1={"First Payment"}  placeHolder2={"Rate per Period"} placeHolder3={"Growth Rate"} placeHolder4={"Number of Periods"}></FinanceFourEntries>   </>}    
                        {selectedText==="GrowingAnnuityPayment1" && <> <FinanceFourEntries endpoint={"general/growingannuitypaymentfrompresentvaluefrompresentvaluerateperperiodgrowthrateandnumberofperiods"} placeHolder1={"Present Value"}  placeHolder2={"Rate per Period"} placeHolder3={"Growth Rate"} placeHolder4={"Number of Periods"}></FinanceFourEntries>   </>}    
                        {selectedText==="GrowingAnnuityPayment2" && <> <FinanceFourEntries endpoint={"general/growingannuitypaymentfromfuturevaluefromfuturevaluerateperperiodgrowthrateandnumberofperiods"} placeHolder1={"Future Value"}  placeHolder2={"Rate per Period"} placeHolder3={"Growth Rate"} placeHolder4={"Number of Periods"}></FinanceFourEntries>   </>}    
                        {selectedText==="PresentValueof3" && <> <FinanceFourEntries endpoint={"general/presentvalueofgrowingannuityfromfirstpaymentrateperperiodgrowthrateandnumberofperiods"} placeHolder1={"First Payment"}  placeHolder2={"Rate per Period"} placeHolder3={"Growth Rate"} placeHolder4={"Number of Periods"}></FinanceFourEntries>   </>}    
                        {selectedText==="PresentValueof4" && <> <FinanceThreeEntries endpoint={"general/presentvalueofgrowingperpetuityfromdividendorcouponatfirstperioddiscountrateandgrowthrate"} placeHolder1={"Dividend or Coupon at First Period"}  placeHolder2={"Discount Rate"} placeHolder3={"Growth Rate"}></FinanceThreeEntries>   </>}    
                        {selectedText==="NumberofPeriods3" && <> <FinanceThreeEntries endpoint={"general/numberofperiodsforpresentvaluetoreachfuturevaluefromfuturevaluepresentvalueandrateperperiod"} placeHolder1={"Future Value"}  placeHolder2={"Present Value"} placeHolder3={"Rate per Period"}></FinanceThreeEntries>   </>}    
                        {selectedText==="PresentValueof5" && <> <FinanceTwoEntries endpoint={"general/presentvalueofperpetuityfromdividendorcouponperperiodanddiscountrate"} placeHolder1={"Dividend or Coupon per Period"}  placeHolder2={"Discount Rate"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PresentValue" && <> <FinanceThreeEntries endpoint={"general/presentvaluefromcashflowafterfirstperiodrateofreturnandnumberofperiods"} placeHolder1={"Cash Flow After First Period"}  placeHolder2={"Rate of return"} placeHolder3={"Number of Periods"}></FinanceThreeEntries>   </>}    
                        {selectedText==="PresentValuewith" && <> <FinanceThreeEntries endpoint={"general/presentvaluewithcontinuouscompoundingfromcashflowrateandtime"} placeHolder1={"Cash Flow"}  placeHolder2={"Rate"} placeHolder3={"Time"}></FinanceThreeEntries>   </>}    
                        {selectedText==="PresentValueFactor" && <> <FinanceTwoEntries endpoint={"general/presentvaluefactorfromrateofreturnandnumberofperiods"} placeHolder1={"Rate of Return"}  placeHolder2={"Number of Periods"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="Ruleof72" && <> <FinanceOneEntry endpoint={"general/ruleof72fromrateexpressedasawholenumber"} placeHolder1={"Rate"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="RateRequiredto" && <> <FinanceOneEntry endpoint={"general/raterequiredtodoublefromlengthoftime"} placeHolder1={"Length of Time"}  ></FinanceOneEntry>   </>} 


                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceGeneral;