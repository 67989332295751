import {React, useState} from "react";
import {Helmet} from "react-helmet";
import TrigonometryAffiliate from "./TrigonometryAffiliate";

function TrigonometryConverter(){
    const [selectedText1, setSelectedText1] = useState('degrees') //default selected value
    const [selectedText2, setSelectedText2] = useState('radians') //default selected value

    const [searchItem1, setSearchItem1] = useState('')


    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const from = selectedText1;
        const to = selectedText2;
        const value = searchItem1;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/trigonometry/conversion/${from}/${to}/${value}`;
   
        if(value !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>


            <Helmet>
                <meta charSet="utf-8"/>
                <title>Angle converter</title>
                <link rel="canonical" href="https://facilemath.com/trigonometry-converter"/>
                <meta name="description" content="Convert angles from degrees to radians, gradians and vice versa"/>
                <meta name="keywords" content="angle converter,angle,angle conversion"/>
                <meta property="og:title" content="Angle converter"/>
                <meta property="og:description"
                      content="Convert angles from degrees to radians, gradians and vice versa"/>
                <meta property="og:site_name" content="Angle converter"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>


            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col text-center w-full mb-12">
                    <h1 className="card-title text-xl text-sky-400  flex justify-center items-center py-2">Angle Converter</h1>
                    <p>Convert units of angles by entering the value to convert and the from and to units (degrees, radians, gradians).

                    </p><br /><hr />
                        <div className="flex flex-row py-2 mt-6">
                            <div className="basis-1/4">
                                <p className="items-center">From</p>
                            </div>
                            <div className="basis-3/4">
                                <select className="select select-bordered w-full" value={selectedText1} onChange={event => setSelectedText1(event.target.value)}>
                                    <option value="degrees">Degrees</option>
                                    <option value="radians">Radians</option>
                                    <option value="gradians">Gradians</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="basis-1/4">
                                <p className="items-center">To  </p>
                            </div>
                            <div className="basis-3/4">
                                <select className="select select-bordered w-full" value={selectedText2} onChange={event => setSelectedText2(event.target.value)}>
                                    <option value="degrees">Degrees</option>
                                    <option value="radians">Radians</option>
                                    <option value="gradians">Gradians</option>
                                </select>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <input  type="text" placeholder="Enter a value" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                        </div>
                        <div className="py-2">
                            <button className="btn bg-orange-700 hover:bg-orange-800 text-white w-full" onClick={startCalculate}>Convert</button>
                        </div>
                        <div className="flex justify-center items-center">
                            { customError && <p className="py-1 text-sm">{customError}</p> }
                        </div>
                        <div className="flex justify-center items-center">
                            { error && <p className="py-1 text-sm">{error.message}</p> }
                        </div>
                        <div className="flex justify-center items-center">
                            { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
                        </div>
                        <div>
                            <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
                        </div>
                        <div className="py-12"> 
                        {/*  <TrigonometryAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TrigonometryConverter;