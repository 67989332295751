import { CalculusTwoEntries, 
    AngouriPolynomialGuide,  LimitExample}
 from "./CalculusTemplates";
    import {React} from "react";
    import {Helmet} from "react-helmet";
    import CalculusAffiliate from "./CalculusAffiliate";



function CalculusLimit(){
    return(
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Find Limits</title>
                <link rel="canonical" href="https://facilemath.com/calculus-limit"/>
                <meta name="description"
                      content="Find the limits of polynomials, trigonometric and exponential functions."/>
                <meta name="keywords" content="limit,limit of a function"/>
                <meta property="og:title" content="Find Limits"/>
                <meta property="og:description"
                      content="Find the limits of polynomials, trigonometric and exponential functions."/>
                <meta property="og:site_name" content="Find Limits"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>


            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Limits</h1>
                    <p>Limits are fundamental in calculus, they are used to define derivatives and integrals. 
                        Essentially, they describe the behavior of a function as it approaches a specific point or value.
                        Limits help us understand the behavior of functions at points where they might not be explicitly defined or might exhibit interesting properties.

                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                    </div>
                        <div>
                          <> <CalculusTwoEntries endpoint={"limit"}  placeHolder1={"Enter a function "} placeHolder2={"bound (x ->) "}></CalculusTwoEntries> <LimitExample></LimitExample>  </>    
                            
                        </div>
                        <div className="card shadow w-full">
                             <AngouriPolynomialGuide ></AngouriPolynomialGuide> 
                        </div>
                        <div className="py-12"> 
                        {/*  <CalculusAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default CalculusLimit;