import {TopographyFiveEntries} from "./TopographyTemplates";
    import {React} from "react";
    import {Helmet} from "react-helmet";
    import area_image from "../../assets/images/quadrilateral.png";
    import TopographyAffiliate from "./TopographyAffiliate";



function TopographyVolume(){
    return(
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Area of a quadrilateral</title>
                <link rel="canonical" href="https://facilemath.com/topography-area"/>
                <meta name="description"
                      content="Calculate the area of a quadrilateral from its sides lengths and the radius of the incribed circle"/>
                <meta name="keywords" content="area,area of a quadrilateral"/>
                <meta property="og:title" content="Area of a quadrilateral"/>
                <meta property="og:description"
                      content="Calculate the area of a quadrilateral from its sides lengths and the radius of the incribed circle"/>
                <meta property="og:site_name" content="Area of a quadrilateral"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>


            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400 flex justify-center items-center py-2">Area of a quadrilateral</h1>
                    <p>Finding the area of a quadrilateral can vary depending on its type—whether it's a square, rectangle, trapezoid, or a general quadrilateral. 
                        In the form below, enter the length of each side of the quadrilateral and the radius of the inscribed circle in order to find the area.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-12">
                        <div>
                            <TopographyFiveEntries endpoint={"quadrilatere"} image={area_image} 
                            placeHolder1={"a"} placeHolder2="b" placeHolder3={"c"} placeHolder4="d"  placeHolder5={"r"}>
                                </TopographyFiveEntries>     
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyVolume;